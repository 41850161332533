import * as React from 'react'
import clsx from 'clsx'
import { CookieConsent } from '@forms/CookieConsent/CookieConsent'
import { Menu } from './Menu'
import { Footer } from './Footer'
import { ScrollToTop } from './ScrollToTop/ScrollToTop'
import styles from './Page.module.css'

export type Props = Readonly<{
  id: string
  showMenu?: boolean
  showFooter?: boolean
  className?: string
  disableScrollToTop?: boolean
}> &
  React.PropsWithChildren

export const Page: React.FC<Props> = ({ id, showMenu = true, showFooter, className, children, disableScrollToTop = false }) => {
  return (
    <div id={id} className={clsx(styles.page,'scrollable-content', className)}>
      <CookieConsent />
      {showMenu && <Menu />}
      <main>{children}</main>
      {disableScrollToTop !== true && <ScrollToTop id={id} />}
      {showFooter !== false && <Footer />}
    </div>
  )
}
